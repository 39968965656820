/* Main Dashboard Container */
._dashboard {
  max-width: 1480px;
  margin: 2rem auto;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto 1fr;
  height: 95vh;
  gap: 3rem;
  overflow: hidden;
}

/* Sidebar Styling */
._dashboard_sidebar {
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  justify-content: space-between;
  max-height: 93vh;
  background-color: #e8e7fdec;
}

/* Sidebar Menu */
._dashboard_menu {
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
  gap: 1.2rem;
  flex-grow: 1; /* Allow the menu to take up available space */
}

/* Sidebar Logout Button */
._dashboard_logout_btn {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.6px;
  width: 100%;
  color: #4a1a98;
  background-color: #ffffff;
  border: transparent;
  border-radius: 0.5rem;
  padding: 0.5rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
  cursor: pointer;
  margin-top: auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

/* Main Content Area */
._dashboard_content {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

/* Header Styling */
._dashboard_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1.3rem 0 1rem;
  border-radius: .75rem;
  position: relative;
  /* background-color: hsl(276, 100%, 99%); */
}

/* ._search_plus_title_container {
  top: 3rem;
  position: absolute;
} */

/* Body Content with Scroll */
._dashboard_body {
  flex-grow: 1;
  max-height: calc(100% - 5rem); /* Adjust based on header height */
  overflow-y: auto;
}

._dashboard_logo {
  width: 13.5rem;
}

._dashboard_menu_ele {
  list-style: none;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
}

._SMK_div {
  position: relative;
}

._coming_soon {
  font-size: .9rem;
  font-style: italic;
  font-family: "Yanone Kaffeesatz", sans-serif;
  position: absolute;
  right: 60%;
}

._menu_li_active {
  background-color: #5e35aa;
  color: white;
}

._header_search {
  width: 33%;
  align-items: center;
}
._header_search,
._search_focused_input_container {
  border: 1.2px solid #383838;
  height: 2.5rem;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  gap: 0.8rem;
  background-color: #ffffff;
  width: 32%;
}

._search_focused_input_container {
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0rem;
}

._header_search input,
._header_search_focused input {
  border: transparent;
  background-color: transparent;
  width: 100%;
  height: 2.5rem;
}

._header_search input:focus,
._header_search_focused input:focus {
  border: none;
  outline: none;
}

._header_search_div {
  background-color: #fbfffe91;
  justify-content: center;
  align-items: center;
  height: 100%;
} 

._header_search_div,
._header_search_focused {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
}

 ._header_search_focused {
  background-color: #f3f0ff;
  max-height: 22rem;
  max-width: 25rem;
  border-radius: .6rem;
  overflow-y: scroll;
  z-index: 10001;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
}

._dashboard_user {
  display: flex;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
}

._user_image {
  width: 2.6rem;
  border-radius: 50%;
  aspect-ratio: 2/2;
  object-fit: cover;
}

._dashboard_username {
  gap: 0.3rem;
  margin: 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

._profile_icon {
  font-size: 1rem;
  color: #383838;

}

._dashboard_username p {
  font-size: .9rem;
  color: #383838;
}

._searched_name {
  margin: .5rem 0;
  font-size: 1rem;
  letter-spacing: .5px;
  padding: .3rem .5rem;
  cursor: pointer;
}

._searched_name:hover {
  background-color: #ffffff;
  border-radius: .4rem;
}

._searched_name span {
  font-size: .8rem;
  color: #4a1a98;
  margin: 0 .6rem;
}

._search_prompt {
  text-align: center;
}

._focused_search_close_icon {
  cursor: pointer;
  position: absolute;
  top: .3rem;
  right: .42rem;
  font-size: 1.3rem;
}
