.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    font-size: .8rem;
    align-items: center;
}

.PaginateNum {
    font-weight: 300;
    text-decoration: none;
    padding: .1rem .3rem;
    margin: .3rem .225rem;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.3s ease;
    background: transparent;
    color: #38383894;
    border: 1px solid #38383894;
}

.active {
    font-weight: 400;
    border-color: #101010;
    background: #a488f8;
    color: #101010;
}

.listStar {
    cursor: pointer;
}