.hero {
  padding: 1rem 1rem 0rem;
  text-align: center;
  border-radius: 1.8rem;
  background-color: #f8f8f8;
  max-width: 1440px;
  margin: 0.6rem auto 0rem;
}

._sample_logos_section {
  max-width: 1440px;
  margin: 6.3rem auto;
  text-align: center;
  padding: 1rem;
}

._hero_text_container {
  margin: 5rem auto 1.5rem;
  width: 75%;
  text-align: center;
}

._hero_text_container h2 {
  font-size: 4.3rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-weight: 600;
  color: #4a1a98;
  line-height: 1;
  letter-spacing: 1.3px;
  margin: 0 auto;
  width: 70%;
}

._hero_img_container {
  width: 85%;
  margin: 3.8rem auto 0rem;
}

._hero_img {
  display: block;
  width: 85%;
  margin: 3.8rem auto 0;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  border-left: 1.4px solid rgb(209, 213, 219);
  border-top: 1.4px solid rgb(209, 213, 219);
  border-right: 1.4px solid rgb(209, 213, 219);
}

._hero_text_container h2 span {
  font-style: italic;
}

._brands_logs {
  font-size: 1.3rem;
  font-family: "Inter Tight", sans-serif;
  color: #8972a3;
}

._the_logos_div {
  width: 80%;
  margin: 0 auto;
  mask-image: linear-gradient(
    to right,
    transparent,
    black 20%,
    black 80%,
    transparent
  );
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* ._bottom_lines {
  position: absolute;
  bottom: 2.2rem;
  left: 0;
  width: 100%;
  z-index: -6;
} */

._hero_text_container p {
  font-size: 1.5rem;
  margin: 0.6rem auto;
  width: 75%;
  font-family: "Inter Tight", sans-serif;
  line-height: 1.36;
  color: #272b31;
}

._hero_text_container p span {
  color: #fcad05;
  background-color: #272b31;
  font-style: italic;
  padding: 0.1rem 0.5rem 0.3rem;
  border-radius: 0.6rem;
  white-space: nowrap;
  font-size: 1.6rem;
}

._get_started_hero {
  border-radius: 0.6rem;
  border: transparent;
  background-color: #4a1a98;
  font-size: 2rem;
  padding: 0.8rem 1.2rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-weight: 600;
  margin: 1.8rem auto;
  cursor: pointer;
  letter-spacing: 1px;
  text-align: center;
  color: white;
}

._get_started_hero:hover {
  color: #272b31;
  background-color: #fcad05;
}

.image_wrapper {
  margin: 1.6rem 2rem;
}

.image_wrapper img {
  width: 6rem;
  height: auto;
  object-fit: contain;
}

._extra_size img {
  width: 10rem;
  height: auto;
  object-fit: contain;
}

@media screen and (max-width: 1042px) {
  ._hero_text_container {
    margin: 4rem auto 5.5rem;
    width: 90%;
  }

  ._hero_text_container h2 {
    font-size: 3.5rem;
    width: 80%;
  }

  ._hero_text_container h2 span {
    font-size: 3.5rem;
  }

  ._hero_text_container p span {
    font-size: 1.6rem;
  }

  ._hero_text_container p {
    font-size: 1.6rem;
    margin: 1.5rem auto 2rem;
  }

  ._get_started_hero {
    font-size: 2rem;
  }

  ._img_div3_list li {
    font-size: 1.3rem;
  }

  ._hero_text_container p span {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 800px) {
    ._hero_text_container h2 {
      font-size: 2.6rem;
      width: 100%;
    }

    ._hero_text_container h2 span {
      font-size: 2.6rem;
    }

    ._hero_text_container p {
      font-size: 1.2rem;
      margin: 1.5rem auto;
    }

    ._get_started_hero {
      font-size: 1.6rem;
      margin: 0;
    }

    @media screen and (max-width: 600px) {
      .hero {
        margin: 0 auto;
        padding-top: 1rem;
        width: 98%;
      }

      ._get_started_hero {
        border-radius: 0.5rem;
        font-size: 1.6rem;
        padding: 0.65rem 1rem;
        /* margin-top: 1rem; */
      }

      ._hero_text_container {
        margin: 2rem auto;
        width: 96%;
      }

      .hero h2 {
        font-size: 2.3rem;
        font-weight: 700;
      }

      .hero h2 span {
        font-size: 2.3rem;
      }

      .hero p {
        width: 100%;
        margin: 1.3rem auto;
        font-size: 1.2rem;
        line-height: 1.3;
      }

      .hero p span {
        font-size: 1rem;
      }

      ._brands_logs {
        font-size: 1.1rem;
      }

      .image_wrapper {
        margin: 1.6rem;
      }

      .image_wrapper img {
        width: 5rem;
        height: auto;
        object-fit: contain;
      }

      ._extra_size img {
        width: 8rem;
        height: auto;
        object-fit: contain;
      }

      ._hero_text_container p span {
        font-size: 1rem;
      }

      ._hero_img {
        display: block;
        width: 100%;
        border-top-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;
      }

      ._sample_logos_section {
        margin: 4.3rem auto;
      }
    }
  }
}
