/* colors: */
/* color: #6a2b73; */
/* color: #a488f8; */
/* color: #4a1a98; */
/* color: #066632; */
/* color: #fb9f3b; */
/* color: #d3ece0; */
/* color: #fee6b1; */
/* color: #fdfaff; */
/* color: #f0eeeb; */
/* color: #383838; */
/* color: #f7defc; */
/* color: #02c0c6; */
/* color: #fcad05; */
/* color: #fd92ae; */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0 .6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  font-family: "Inter Tight", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.creator_public_page,
.Home {
  margin: auto;
  overflow: hidden;
}

a {
  color: black;
  text-decoration: none;
}

.signUpPage,
.loginPage,
.verificationPage,
.checkYourEmailPage {
  max-width: 1180px;
  margin: auto;
  overflow: hidden;
}

.loginPage {
  margin: 2rem auto;
}

.TheLoader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fbfffe91;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.spinnerImg {
  width: 8rem;
}

.msgContainer {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.msgContainer div {
  width: 55%;
  background-color: #fbfffe;
  padding: 2rem;
  border-radius: 0.8rem;
  box-shadow: rgba(9, 207, 35, 0.398) 0px 1px 3px 0px, rgba(9, 207, 35, 0.398) 0px 0px 0px 1px;
}

.msgContainer div h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: rgb(74, 74, 74);
}

.msgContainer div p {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem 0;
  color: #2f5bd4;
  line-height: 1.5;
}

button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.loader_div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50% - -50%);
}

.loader_img {
  width: 5rem;
}

.App {
    margin: auto 1.5rem;
  }

@media screen and (max-width: 1024px) {
  @media screen and (max-width: 800px) {
    .msgContainer div h1 {
      font-size: 1.8rem;
    }

    .msgContainer div {
      width: 85%;
      padding: 1.5rem 1.5rem 1rem;
    }

    .loginPage {
      margin: 1.2rem auto;
    }

    @media screen and (max-width: 600px) {
      .App {
        margin: auto;
        width: 98%;
        overflow: hidden;
      }

      .msgContainer div {
        width: 95%;
      }

      .loginPage {
        margin: .2rem auto;
      }
    }
  }
}