.Footer {
  margin: 1.8rem auto 2rem;
  border-top: 1px solid #4a1a98;
  max-width: 1280px;
}

.footerLogo {
  width: 7.42rem;
}

.logoAndSM {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: .8rem;
}

.SocialIcons {
  display: flex;
  gap: .8rem;
  font-size: 1.3rem;
  color: #5c5c5cdb;
  cursor: pointer;
}

.footerLinks {
  display: flex;
  gap: 6rem;
  margin: 1rem 0;
  padding: .5rem;
}

.footerLinks div h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: .5rem 0;
}

.footerLinks div p {
  margin-top: .8rem;
  font-size: 1.05rem;
  font-weight: 400;
  cursor: pointer;
}

.footerMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 1rem 3rem 2rem; */
}

.copy {
  display: flex;
  flex-direction: column;
  gap: .3rem;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #4a1a98;
  padding-top: .5rem;
  color: #4a1a98;
}

.DevName {
  display: flex;
  gap: .3rem;
  align-items: center;
  cursor: pointer;
}

.sabli {
  margin: .42rem 0;
}

._footer_logo {
  width: 12rem;
  cursor: pointer;
}

@media screen and (max-width: 1042px) {
  @media screen and (max-width: 800px) {
    ._footer_logo {
      width: 11rem;
    }

    .footerLinks {
        gap: 3rem;
    }
    @media screen and (max-width: 600px) {
      .Footer {
        margin: 3.8rem 0 2rem;
        border-top: 1px solid #4a1a98;
      }

      .footerMain {
        flex-direction: column-reverse;
        margin: 1rem auto;
      }

      .footerLogo {
        width: 5.3rem;
      }

      .SocialIcons {
        font-size: 1rem;
      }

      .footerLinks {
        margin: 0rem auto 2rem;
      }

      .footerLinks div p {
        font-size: .9rem;
      }

      .logoAndSM {
        gap: .42rem;
        align-items: center;
      }

      ._footer_logo {
        width: 9rem;
      }

      .footerLinks {
          gap: 5rem;
      }

    ._get_started_btn {
    font-size: 1.3rem;
}
    }
  }
}