/* .le_dashboard_img {
  width: 100%;
  border-radius: 1.2rem;
} */

/* ._middle_img_container {
  width: 100%;
  border-radius: 1.2rem;
  margin: 0 auto;
  height: 100%;
} */

._feature_video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0.8rem;
}

.mainGrid {
  margin: 8rem auto;
  max-width: 1280px;
  text-align: center;
}

/* ._features_div {
  display: flex;
  gap: 1.8rem;
  border-radius: 1rem;
  padding: 1rem 1.3rem;
  width: 100%;
  margin: 3rem auto 1rem;
}

._feature_containers div {
  cursor: pointer;
} */

/* background: #f8f8f8; */

/* ._feature_containers {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: left;
  width: 42%;
  align-items: center;
  justify-content: center;
} */

/* ._feature_text_containers {
  padding: 0.6rem 1rem;
  border-radius: 0.6rem;
} */

/* ._features_div div h3 {
  margin: 0 0 0.5rem;
  font-size: 1.2rem;
  font-family: "Inter Tight", sans-serif;
} */

/* ._feature_text_containers div p {
  line-height: 1.3;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.1rem;
  color: #404040;
} */

/* ----------------------------------------- Features ---------------------------------------------------- */
._features_title {
  text-align: center;
}

._features_title h3 {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-size: 4.42rem;
  color: #4a1a98;
}

._features_title p {
  font-size: 1.6rem;
  width: 70%;
  margin: 0.3rem auto;
  line-height: 1.3;
  color: #4f4f4f;
  font-family: "Inter Tight", sans-serif;
}

._features_container {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  position: relative;
}

._feature_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._middle_img_container {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  border-radius: 1.2rem;
  margin: 0 auto;
  height: 100%;
}

._feature_video {
  border-radius: 1.2rem;
}

._feature_text {
  padding: 0.5rem 0.75rem;
  border-radius: 0.6rem;
  text-align: left;
  width: 38%;
  cursor: pointer;
}

._d_0 {
  background-color: #fd92ae;
}

._d_1 {
  background-color: #fcad05;
}

._d_2 {
  background-color: #02c0c6;
}

._feature_text h3 {
  margin: 0 0 0.5rem;
  font-size: 1.2rem;
  font-family: "Inter Tight", sans-serif;
}

._feature_text p {
  line-height: 1.3;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.1rem;
  color: #404040;
}

._features_btn {
  border-radius: 0.6rem;
  border: transparent;
  background-color: #4a1a98;
  font-size: 1.8rem;
  padding: 0.8rem 1.2rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-weight: 600;
  margin: 0rem auto 2rem;
  cursor: pointer;
  letter-spacing: 1px;
  text-align: center;
  color: white;
}

._features_btn:hover {
  color: #272b31;
  background-color: #fcad05;
}

@media screen and (max-width: 1042px) {
  /* .le_dashboard_img {
    width: 90%;
  } */

  /* ._features_div {
    width: 90%;
  } */

  ._features_title p {
    width: 80%;
  }

  ._feature_text {
    width: 40%;
  }

  ._middle_img_container {
    max-width: 58%;
    height: 100%;
  }

  @media screen and (max-width: 800px) {
    .mainGrid {
      margin: 5rem 0;
    }

    ._features_title h3 {
      font-size: 3rem;
    }

    ._features_title p {
      font-size: 1.2rem;
      width: 90%;
      line-height: 1.3;
    }

    /* .le_dashboard_img {
      width: 100%;
    }

    ._features_div {
      padding: 0;
      width: 100%;
      gap: 1rem;
    } */

    ._feature_container {
      flex-direction: column;
      gap: 1.2rem;
    }

    ._middle_img_container {
      position: relative;
      top: 0;
      right: 0;
      max-width: 100%;
      height: 100%;
    }

    ._feature_text {
      width: 100%;
    }

    @media screen and (max-width: 600px) {
      .mainGrid {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      ._features_title h3 {
        font-size: 2.42rem;
      }

      ._features_btn {
        font-size: 1.6rem;
        margin: 0.5rem auto 2rem;
      }

      ._features_title p {
        font-size: 1.2rem;
        width: 95%;
        line-height: 1.3;
      }

      /* ._features_div div h3 {
        font-size: 1rem;
        font-weight: 700;
      } */

      /* ._features_div div p {
        font-size: 1rem;
      } */

      ._middle_img_container {
        width: 100%;
        height: 20rem;
      }

      ._feature_video {
        width: 100%;
      }

      ._features_container {
        margin: 3rem auto 1rem;
      }
    }
  }
}
