.verify-container,
.emailVerifiedSection,
.emailVerifiedSectionErr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  margin: 8rem auto 10rem;
  background-color: #fbfffe;
  padding: 1.6rem 1rem;
  border-radius: 1.2rem;
  box-shadow: rgba(162, 162, 162, 0.156) 0px 2px 8px 0px;
}

.emailImg {
  width: 8rem;
}

.verify-container h1 {
  font-size: 2rem;
  font-weight: 800;
  font-family: 'Archivo', sans-serif;
  margin: 1.42rem 0;
}

.verify-container p {
  font-size: 1.2rem;
  font-family: 'Avenue', sans-serif;
  width: 85%;
  text-align: center;
  line-height: 1.2;
}

.verifyBtn {
  height: 3.42rem;
  border-radius: .62rem;
  border: transparent;
  background-color: #4a1a98;
  color: #fbfffe;
  font-size: 1.42rem;
  font-family: 'Archivo', sans-serif;
  margin: 2rem 0;
  padding: .5rem 1rem;
  font-weight: 600;
  font-style: normal;
  cursor: pointer;
  letter-spacing: .5px;
}

.checkSpam {
  font-size: 1.4rem;
  font-weight: 700;
  color: #d4145ad3;
  font-style: italic;
  font-family: 'Source Serif 4', serif;
}

.userEmailSpan {
  font-size: 1.2rem;
  font-weight: 700;
  color: #a034f6;
  font-style: italic;
  font-family: 'Source Serif 4', serif;
}

.verify-container button:disabled,
.verify-container button[disabled] {
  border: 1px solid black;
  background-color: grey;
  color: white;
  padding: 15px;
  cursor: not-allowed;
}

.verifyError {
  font-size: 1.2rem;
  color: red;
  border-bottom: 2px solid red;
  background-color: rgba(239, 209, 209, 0.693);
  padding: .5rem .3rem;
  border-radius: .42rem;
}

#certify {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
}

/* 00000000000000000000000000000000000000000000000000000000000000000000000000000000000000 */
/* Email Checked Section */

.emailVerifiedSection {
  border-bottom: rgb(13, 173, 13);
  border-bottom-style: solid;
  border-bottom-width: 3.3px;
}

.emailVerifiedSectionErr {
  border-bottom: red;
  border-bottom-style: solid;
  border-bottom-width: 3.3px;
}

.checkedMail {
  width: 8rem;
}

.verifyTitle {
  font-size: 2rem;
  color: rgb(13, 173, 13);
  font-weight: 800;
  font-family: 'Archivo', sans-serif;
  margin: 1.42rem 0 .8rem;
}

.verifyTitleErr {
  font-size: 1.8rem;
  color: red;
  font-weight: 800;
  font-family: 'Archivo', sans-serif;
  margin: 1.42rem 0 .8rem;
}

.verifyMsgErr {
  font-size: 1.2rem;
  font-family: 'Avenue', sans-serif;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 1.2rem;
}

.verifyMsg {
  font-size: 1.2rem;
  font-family: 'Avenue', sans-serif;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 1.2rem;
}

@media screen and (max-width: 1042px) {
  @media screen and (max-width: 800px) {
    .verify-container h1 {
      font-size: 1.8rem;
    }

    .verify-container p {
      font-size: 1.2rem;
      width: 95%;
    }

    .verifyBtn {
      height: 3rem;
      font-size: 1.3rem;
      font-weight: 600;
    }

    .verify-container {
      width: 85%;
      margin: 5rem auto;
    }

    .emailImg {
      width: 7rem;
    }

    .checkSpam {
      font-size: 1.5rem;
    }

    .verifyTitle,
    .verifyTitleErr {
      font-size: 1.6rem;
      font-weight: 600;
    }

    @media screen and (max-width: 600px) {
      .verify-container h1 {
        font-size: 1.6rem;
      }

      .verify-container {
        width: 95%;
        margin: 5rem auto;
      }

      .checkSpam {
        font-size: 1.2rem;
        font-weight: 500;
      }

      .verify-container p {
        font-size: 1rem;
        width: 99%;
      }

      .verifyBtn {
        height: 2.8rem;
        font-size: 1.3rem;
        font-weight: 600;
      }

      .emailImg {
        width: 6rem;
      }

      .userEmailSpan {
        font-size: 1rem;
        font-weight: 500;
      }

      #certify {
        font-size: 1rem;
        font-weight: 600;
      }

      .verifyMsgErr,
      .verifyMsg {
        font-size: 1rem;
        margin-bottom: 1rem;
      }

      .checkedMail {
        width: 6rem;
      }

      @media screen and (max-width: 400px) {
        .verify-container h1 {
          font-size: 1.4rem;
        }

        .verifyBtn {
          height: 2.5rem;
          font-size: 1.2rem;
          font-weight: 600;
        }
      }
    }
  }
}