._questions_container {
  text-align: center;
  margin: 8rem auto;
}

._questions_container h2 {
  font-size: 2.3rem;
  margin: .5rem auto;
  color: #4a1a98;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

._q_title_text {
  font-size: 1.5rem;
  color: #8972a3;
}

._questions {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  align-items: center;
  justify-content: flex-start;
  margin: 2.8rem auto;
  text-align: left;
  max-width: 80%;
}

._questions_div {
  background-color: #eee8fd;
  padding: 1rem 1.5rem;
  border-radius: .6rem;
  width: 70%;
}

._questions_div p {
  color: rgb(103, 107, 107);
  font-size: 1rem;
  line-height: 1.5;
  margin: 1rem auto;
}

._q_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._questions_div_none {
  display: none;
}

._questions_div_reveal {
  display: block;
}

._questions_div h4 {
  color: #4a1a98;
}

._q_icon {
  color: #4a1a98;
  cursor: pointer;
  font-size: 1.5rem;
}

._questions_icon {
  width: 6.5rem;
}

.q_chat_logo {
  width: 6rem;
  margin: 1.3rem auto;
}

._q_chat_title {
  font-size: 1.6rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
  color: #4a1a98;
}

._q_chat_text {
  color: #272b31;
  font-size: 1.3rem;
  margin: 1rem auto 2rem;
  line-height: 1.5;
  width: 60%;
}

._q_chat_text span {
  color: #4a1a98;
}

._q_chat_div {
  width: 65%;
  margin: 0 auto 5rem;
}

._get_started_btn {
  border-radius: .6rem;
  border: transparent;
  background-color: #4a1a98;
  font-size: 1.6rem;
  padding: .8rem 1.2rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-weight: 700;
  cursor: pointer;
  letter-spacing: .8px;
  text-align: center;
  color: white;
}

._get_started_btn:hover {
  color: #272b31;
  background-color: #fcad05;
}

._q_click_here {
  font-style: italic;
  color: #4a1a98;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 1042px) {
  ._questions_div {
    padding: 1rem 1rem;
    width: 80%;
  }

  ._q_chat_div {
    width: 95%;
    margin: 0 auto 5rem;
  }

  @media screen and (max-width: 800px) {
    ._questions {
      max-width: 99%;
      margin: 2rem auto;
    }

    ._q_chat_text {
      width: 90%;
    }

    @media screen and (max-width: 600px) {
      ._questions_container {
        margin: 5rem auto;
      }

      ._questions_div {
        width: 95%;
      }

      ._questions_div h4 {
        color: #4a1a98;
        font-size: .85rem;
      }

      ._q_chat_div {
        width: 95%;
      }

      .q_chat_logo {
        width: 4.2rem;
      }

      ._q_chat_title {
        font-size: 1.5rem;
      }

      ._q_chat_text {
        margin: .8rem auto;
        font-size: 1.2rem;
      }

      ._get_started_btn {
        font-size: 1.6rem;
        margin: 1.8rem auto;
      }
    }
  }
}