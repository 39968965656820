._hb_icon {
  width: 4.8rem;
}

/* #_hb_creators_icon {
  width: 4rem;
} */

._hb_header,
._hb_ele_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

._hb_header {
  margin-top: 1.6rem;
  display: flex;
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  gap: 4.8rem;
}

._hb_ele_container {
  gap: 1.6rem;
  flex-grow: 1;
}

._text_holder {
  display: flex;
  flex-direction: column;
  gap: .6rem;
}

._table_pagination_section {
  margin-top: 2rem;
  max-height: 90%;
  overflow: hidden;
  overflow-y: auto;
}

._hb_ele_container {
  padding: 1rem 1.3rem;
  border-radius: .6rem;
}

._hb_ele_container:nth-child(1) {
  background-color: #FEE4CB;
}

._hb_ele_container:nth-child(2) {
  background-color: #FFD3E2;
}

._hb_ele_container:nth-child(3) {
  background-color: #DBF6FD;
  gap: 1.3rem;
}

/* --------------------------------------------------------------------------------------------------- */
/* Data Table Styling */

/* ._la_sponsors_title {
   font-size: 2.3rem;
  color: #4a1a98;
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-style: italic;
  letter-spacing: .3px;
}
*/

/* Reset the margins and padding for a cleaner base of a table */
body, table, tr, td, th {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Table styling */
.la_table {
  margin: 2.8rem 0 2.3rem;
  margin-bottom: 1.6rem;
  width: 100%;
  border-collapse: collapse; 
}

.la_table_sponsor {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.3rem;
}

._table_head th {
  color: #4a1a98;
  font-size: 1.1rem;
  font-weight: 400;
  padding: .3rem;
  border-bottom: 1.5px solid #8b8b8b;
  text-align: center;
}

._table_body tr {
  height: 4rem;
}

._table_body td {
  padding: 1rem;
  vertical-align: middle;
  text-align: center;
  border-bottom: .6px solid #38383894;
}

/* First row (sponsor logo and name) */
._first_row div {
  display: flex;
  align-items: center; 
  justify-content: flex-start;
  gap: 1.3rem;
  height: 100%;
}

._logo_name {
  cursor: pointer;
}

._add_to_favorite {
  font-size: 1.6rem;
  color: hsl(0, 93%, 47%);
  cursor: pointer;
}

._sponsors_logos {
  width: 2.5rem;
  height: auto;
  display: block; /* Ensure the image doesn't affect surrounding elements */
}

._first_row div li {
  list-style: none;
  font-size: 1.1rem;
  text-align: left;
  margin: 0;
  padding: 0;
}

#_sponsor_name {
  text-align: left; 
  padding-left: 1rem;
 }

._dashboard_industry,
._dashboard_sponsorships,
._dashboard_pIdx {
  font-size: .9rem;
}

._popularity_idx_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .3rem;
}

/* Tags row styling */
._tags_row div {
  display: flex;
  flex-wrap: wrap;
  gap: .8rem;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

._sponsor_tags {
  list-style: none;
  font-size: .8rem;
  padding: .3rem .5rem;
  border-radius: .3rem;
  background-color: #efeef0;
  white-space: nowrap; /* Prevent tags from breaking into multiple lines */
}

._this_month {
  font-size: 1rem;
  color: #38383894;
}

._dataDown {
  color: hsl(0, 93%, 47%);
}

._dataUp {
    color: hsl(150, 93%, 28%);
}

._stats_holder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

._stats_title {
  color: #000;
  font-size: 1.1rem;
}

._stats_numbers {
  font-size: 1.3rem;
}

._dashboard_creator_img {
  width: 2rem;
  border-radius: 50%;
  border: 1.6px solid #ffffff;
  position: absolute;
  bottom: -2rem;
}

._dashboard_creator_img:nth-child(1) {
  left: -3rem;
}

._dashboard_creator_img:nth-child(2) {
  left: -1.5rem;
}

._dashboard_creator_img:nth-child(3) {
  left: 0rem;
}

._dashboard_creator_img:nth-child(4) {
  left: 1.5rem;
}

._dashboard_creator_img:nth-child(5) {
  left: 3rem;
}