._favorites_container {
  display: flex;
  align-items: flex-start;
  margin: 3.6rem auto;
  gap: 10rem;
}

._favorites_section {
  margin-top: 1.6rem;
}

._no_favorite_msg {
  font-size: 1.2rem;
  color: #38383894;
}

._favorites_section h2 {
  margin-top: 1.6rem;
  color: #4a1a98;
  font-family: "Yanone Kaffeesatz", sans-serif;
  letter-spacing: 0.3px;
}

._channel_container,
._sponsor_container {
  overflow-y: auto;
  height: calc(100vh - 16.6rem);
}

._channel_div,
._sponsor_div,
._channel_subs_container,
._channel_container ._channel_info {
  display: flex;
  align-items: center;
}

._channel_div {
  gap: 2.6rem;
  margin: 1.3rem 0;
}

._channel_handle {
  color: #38383894;
  font-size: 0.8rem;
  margin: 0.2rem;
}

._sponsor_div {
  margin: 1.3rem 0;
}

._sponsor_div,
._channel_div ._channel_info {
  gap: 1rem;
  cursor: pointer;
}

._sponsors_logos {
  width: 3.2rem;
}

._channels_img {
  width: 3.2rem;
  border-radius: 50%;
}

._channel_subs {
  margin-left: 0.4rem;
  font-size: 0.9rem;
}

._subs_icon {
  color: #38383894;
}

._sponsors_title,
._channels_title {
  font-size: 1.75rem;
  color: #000;
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-style: italic;
  letter-spacing: 0.3px;
  position: sticky;
  z-index: 1;
  top: 0;
  margin: 0 0 1.2rem;
  background-color: #ffffff;
}

._section_title {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-size: 2.3rem;
  letter-spacing: 0.3px;
}
