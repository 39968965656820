._sponsor_site_icon {
  width: 6rem;
  display: block;
}

._sponsors_section {
  margin: 2.6rem 0;
}

._channel_name_sponsor {
  font-size: .8rem;
  text-align: left;
  color: #38383894;
}

._names_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: .2rem;
}

._num_spon_vids {
  color: #38383894;
}

._num_spon_vids,
._sponsor_link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .6rem;
}

._num_spon_vids span {
display: flex;
gap: .6rem;
align-items: center;
}