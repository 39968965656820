/* ._search_plus_section {
  position: relative;
} */

._search_plus_sponsors_container {
  margin: 2rem 0;
}

._search_containers {
  display: flex;
  gap: 1rem;
  width: 80%;
  margin: 1rem 0;
  flex-wrap: wrap;
}

._search_containers input,
._search_containers select {
  width: 40%;
  height: 2.5rem;
  border-radius: .4rem;
  border: 1px solid #383838;
  padding: .5rem;
}

._search_plus_title_container {
  margin-top: 1.3rem;
}

/* ._search_containers select:focus,
._search_containers input:focus {
  outline: none;
  border: 1.5px solid #4a1a98;
}

._search_containers select option {
  padding: .4rem;
} */

/* ._search_plus_btn {
  font-size: 1.3rem;
  color: #ffffff;
  background-color: #4a1a98;
  border-radius: .6rem;
  border: transparent;
  padding: .2rem .6rem;
  cursor: pointer;
  height: 2.8rem;
  width: 40%;
} */

._search_plus_title {
  font-size: 2.8rem;
  font-style: italic;
  font-family: "Yanone Kaffeesatz", sans-serif;
  letter-spacing: .5px;
  color: #4a1a98;
}

._search_plus_text {
  color: #383838;
  font-size: 1.2rem;
  margin: .3rem 0 2rem;
}

._no_res_text {
  font-size: 1.3rem;
  color: #38383894;
  width: 75%;
  line-height: 1.5;
  letter-spacing: .2px;
  margin: 6rem 0;
}

/* ._res_container {
  margin: 2.6rem 0 2rem;
} */

._searched_channel_info,
._searched_channel {
  display: flex;
}

._searched_channel{
  gap: 1rem;
  margin: 1.3rem;
}

._searched_channel_info {
  flex-direction: column;
  gap: .8rem;
  align-items: flex-start;
  justify-content: center;
}

._searched_channel_pic {
  width: 6rem;
  border-radius: 50%;
}

._search_containers select {
  width: 40%;
  height: 2.5rem;
  border-radius: 0.4rem;
  border: 1px solid #383838;
  padding: 0.5rem 1rem;
  appearance: none; /* Hides default dropdown icon */
  background-color: #fff;
  position: relative;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="gray" d="M7 10l5 5 5-5H7z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 1rem center; /* Aligns the dropdown icon */
  background-size: 1rem;
  position: relative;
}

._search_containers select:focus,
._search_containers select:hover {
  outline: none;
  border: 1.5px solid #4a1a98;
}

._search_containers select option {
  padding: 0.5rem;
  background-color: #fff;
  color: #383838;
}

._search_containers select option:hover {
  background-color: #e0e0e0;
}

._search_plus_btn {
  font-size: 2rem;
  color: #ffffff;
  background-color: #4a1a98;
  border-radius: 0.6rem;
  border: transparent;
  padding: 0.2rem 0.6rem;
  cursor: pointer;
  letter-spacing: .2px;
  height: 2.8rem;
  width: 40%;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

.scrollable-container {
  max-height: 500px;
  overflow-y: auto; 
  overflow-x: hidden;
}
