.form_div {
  max-width: 1280px;
  margin: 5rem auto 8rem;
  text-align: center;
}

._chose_plan_container {
  max-width: 1280px;
  margin: 2rem auto 8rem;
  text-align: center;
}

._bg_drop {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #acacac6e;
  width: 100%;
  height: 100%;
}

.contact_container {
  text-align: center;
  max-width: 1280px;
  background-color: #f8f8f8;
  padding: 2.8rem 3.8rem;
  border-radius: 1.3rem;
  margin: auto;
}

.contact_container h3 {
  font-size: 3.8rem;
  letter-spacing: 1px;
  color: #4a1a98;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

.contact_container p {
  font-size: 1.3rem;
  width: 70%;
  margin: 1rem auto;
  line-height: 1.3;
  color: rgb(103, 107, 107);
}

.contact_container p span {
  font-style: italic;
  color: #403d56;
}

.contact_socials_container {
  align-items: center;
  margin: 3rem auto;
}

.contact_socials_container a {
  font-size: 2rem;
  margin: 3rem 0.8rem;
  color: #403d56;
}

#_contact_svg {
  width: 40%;
}

.form_div form {
  display: flex;
  gap: 2.6rem;
}

.form_div p {
  font-size: 1.3rem;
  margin: 5rem auto;
}

.form_div form input {
  height: 2.42rem;
  width: 70%;
  padding: 0.2rem 0.5rem;
  border-radius: 0.6rem;
  border: 2px solid #4a1a98;
  margin: auto;
}

.form_div form button {
  border: none;
  background-color: #4a1a98;
  color: aliceblue;
  font-size: 1rem;
  border-radius: 0.6rem;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
}

/* ---------------------------------------------------------------------------------- */
/* 404 Page Style */
._404_svg {
  width: 100%;
  max-width: 800px;
}

._404_page_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5rem auto 8rem;
}

._not_found_title {
  font-size: 3rem;
  font-weight: 700;
  color: #d4145a;
  margin: 2rem 0;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

/* ------------------------------------------------------------------------------------------ */
/* Waiting List Styling */

._wait_list_container {
  max-width: 1280px;
  margin: 5rem auto;
  text-align: center;
}

._waitlistt_headline h2 {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-size: 3rem;
  width: 55%;
  margin: 0.6rem auto;
  line-height: 1.3;
  letter-spacing: 0.2px;
  color: #272b31;
}

._waitlistt_headline h2 span {
  color: #fcad05;
  font-style: italic;
  background-color: #272b31;
  padding: 0.3rem 0.5rem 0.15rem;
  font-size: 2.6rem;
  border-radius: 0.5rem;
}

._waitlistt_headline p {
  width: 75%;
  margin: 0.3rem auto;
  font-size: 1.3rem;
  line-height: 1.3;
  color: #606060;
  letter-spacing: 0.1px;
}

._waiting_form {
  margin: 4.2rem auto;
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

._waiting_form input {
  width: 100%;
  height: 2.6rem;
  border-radius: 0.6rem;
  padding: 0.3rem 0.72rem;
  outline: none;
  border: 1.3px solid #4a1a98;
}

._waiting_form input:focus {
  border: 1.6px solid #fcad05 !important;
  outline: none;
}

._waiting_form button {
  margin: 2rem;
  font-size: 2rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-weight: 600;
  letter-spacing: 0.15px;
  padding: 0.65rem 0.8rem;
  border-radius: 0.6rem;
  border: transparent;
  background-color: #4a1a98;
  color: #fff;
}

._waiting_form button:hover {
  color: #272b31;
  background-color: #fcad05;
}

._inputsErrMsg {
  color: red;
  font-size: 1rem;
  background-color: rgba(239, 209, 209, 0.693);
  padding: 0.5rem 2rem;
  border-radius: 0.42rem;
}

/* --------------------------------------- Privacy Policy -------------------------------------------------- */

._privacy_container,
._policy_points_container,
._policy_title {
  display: flex;
  flex-direction: column;
}

._privacy_container {
  max-width: 1280px;
  margin: 1rem auto;
  background-color: #f8f8f8;
  padding: 1.6rem 2rem;
  border-radius: 2rem;
  gap: 3rem;
}

._policy_title {
  gap: 0.6rem;
}

._policy_title h1 {
  color: #4a1a98;
  font-size: 2.6rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

._privacy_container span p {
  font-size: 1.2rem;
  line-height: 1.3;
  color: #272b31;
}

._policy_points_container {
  gap: 2.3rem;
}

._policy_points_container div p {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  color: #606060;
  line-height: 1.3;
}

._policy_points_container div span {
  color: #d4145a;
}

._policy_points_container div ul {
  margin: 1.3rem 0;
}

._policy_points_container div li {
  margin: 0.7rem 1.2rem;
  color: #403d56;
  line-height: 1.3;
}

._unavailability_message {
  color: #4a1a98;
  font-size: 3.6rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
  width: 80%;
  margin: auto;
}

._unavailability_message_notice {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-size: 2.6rem;
  margin: 1rem auto;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 1042px) {
  ._waitlistt_headline h2 {
    width: 75%;
  }

  ._unavailability_message {
    font-size: 2.3rem;
    width: 90%;
  }

  ._unavailability_message_notice {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 800px) {
    .contact_container p {
      width: 95%;
      margin: 1rem auto;
    }

    #_contact_svg {
      width: 90%;
      height: 100%;
    }

    ._waitlistt_headline h2 {
      font-size: 2rem;
      width: 75%;
    }

    ._waitlistt_headline h2 span {
      font-size: 1.7rem;
    }

    ._waitlistt_headline p {
      width: 80%;
      font-size: 1.2rem;
    }

    ._waiting_form {
      width: 50%;
    }

    ._waiting_form button {
      font-size: 1.6rem;
    }

    @media screen and (max-width: 600px) {
      .contact_container,
      ._chose_plan_container {
        padding: 1rem 1.3rem;
        margin-top: 1rem;
      }

      .form_div form {
        flex-direction: column;
        gap: 1.6rem;
      }

      .form_div form button {
        height: 2.8rem;
        width: 60%;
        margin: auto;
      }

      .form_div form input {
        width: 90%;
      }

      .contact_container h3 {
        font-size: 1.8rem;
      }

      .contact_container p {
        font-size: 1rem;
        width: 95%;
        margin: 1rem auto;
      }

      .contact_socials_container {
        margin: 2rem auto;
      }

      .contact_socials_container a {
        font-size: 1.5rem;
        margin: 0 0.6rem;
        color: #403d56;
      }

      #_contact_svg {
        width: 110%;
        height: 100%;
      }

      ._waitlistt_headline h2 {
        font-size: 1.8rem;
        width: 85%;
      }

      ._waitlistt_headline h2 span {
        font-size: 1.6rem;
      }

      ._waitlistt_headline p {
        width: 95%;
        font-size: 1rem;
      }

      ._waiting_form {
        width: 80%;
      }

      ._waiting_form button {
        font-size: 1.5rem;
        font-weight: 700;
      }

      ._privacy_container {
        max-width: 97%;
        padding: 1.3rem 1rem;
      }

      ._policy_title h1 {
        font-size: 1.6rem;
      }

      ._policy_points_container div p {
        font-size: 1rem;
      }

      ._privacy_container span p {
        font-size: 1rem;
      }

      ._policy_title p {
        font-size: 0.8rem;
      }

      ._unavailability_message {
        font-size: 1.6rem;
        width: 90%;
      }

      ._unavailability_message_notice {
        font-size: 1.42rem;
      }
    }
  }
}
