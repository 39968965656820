._sponsor_wave {
  width: 13rem;
}

._header_logo_div {
  position: relative;
}

._beta {
  position: absolute;
  bottom: -.6rem;
  right: 1.5rem;
  color: #383838;
  font-size: 1rem;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  background-color: #fffeff;
  padding: .8rem 1rem;
  border-radius: 1.1rem;
  margin: 1.3rem auto .5rem;
}

.navStuff {
  display: flex;
  gap: 3.42rem;
  align-items: center;
}

.navStuff nav {
  display: flex;
  list-style: none;
  font-size: 1.4rem;
  gap: 1.3rem;
  font-weight: 500;
  cursor: pointer;
  align-items: center;
}

.navButtons {
  display: flex;
  align-items: center;
  gap: 1.3rem;
}

#_login_text {
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  color: #4a1a98;
}

._faq {
  font-size: 1.2rem;
  cursor: pointer;
}

.navButtons p {
  font-size: 1.5rem;
  font-weight: 600;
}

.navButtons button {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0.3rem 0.7rem;
  border-radius: 0.6rem;
  background: #4a1a98;
  border: transparent;
  color: aliceblue;
  font-style: normal;
  cursor: pointer;
}

.menuIcon {
  font-size: 2.15rem;
  display: none;
}

._dashboard_btn {
  text-align: center;
}

@media screen and (max-width: 1042px) {

    .navButtons button {
      font-size: 1.5rem;
    }

  @media screen and (max-width: 800px) {
    ._sponsor_wave {
      width: 8.5rem;
    }

    @media screen and (max-width: 600px) {
      .navButtons {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
      }

      .navButtons p {
        font-size: 1.2rem;
      }

      .Header {
        margin: 1rem 0 .5rem;
        padding: .3rem .62rem;
        position: relative;
        border-radius: .6rem;
      }

      .navStuff nav {
        flex-direction: column;
        gap: 1.3rem;
      }

      .navStuff {
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        top: 3.42rem;
        right: -100%;
        border-radius: 0.75rem;
        padding: 2.5rem 0 0 1.25rem;
        width: 250px;
        height: 100vh;
        box-shadow: rgba(142, 171, 180, 0.73) 0px 2px 8px 0px;
        backdrop-filter: blur(.875rem);
        transition: .3s all ease-in-out;
        z-index: 100;
        gap: 2rem;
      }

      .expand {
        right: 0.2188rem;
        transition: right 0.3s ease;
      }

      .menuIcon {
        display: block;
      }

      .navButtons {
        align-self: self-start;
      }

      .navButtons button {
        font-size: 1.2rem;
      }

      ._beta {
        bottom: -.45rem;
        right: .8rem;
        font-size: .7rem;
      }
    }
  }
}