.channels_table {
  margin: 2.3rem 0 2.3rem;
  width: 100%;
  border-collapse: collapse;
}

.channel_names,
._sponsor_img_info_span {
  gap: 0.42rem;
}

._title_and_desc,
.channel_names,
._details_name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
}

._sponsored_video_img_desc {
  margin: 1rem 0 0.6rem;
  border-bottom: 0.8px solid #38383894;
  padding-bottom: 0.6rem;
}

._sponsored_sponsor_img {
  width: 2rem;
  border-radius: 50%;
}

._middle_stats_containers p {
  margin: 0.5rem 0;
}

._channel_subs,
._details_text,
._img_text_details,
._channel_link,
._back_btn,
._sponsored_video_text,
._sponsor_img_info_span,
._num_of_spon_vids,
._d_subs_spon_vids {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 60%;
}

._channel_subs {
  gap: 0.3rem;
}

._tags_row p {
  color: #38383894;
}

._channel_link,
._img_text_details {
  gap: 0.8rem;
}

._detail_icon {
  font-size: 2rem;
}

._title_and_desc,
._sponsored_video_img_desc {
  gap: 1.3rem;
}

._sponsored_video_text,
._details_text {
  gap: 2.8rem;
}

._d_subs_spon_vids,
._num_of_spon_vids {
  gap: 0.3rem;
  font-size: 1rem;
  color: #6e6e6e;
}

._num_of_spon_vids {
  justify-content: center;
  gap: .5rem;
  align-items: center;
}

._channel_username {
  font-size: 0.8rem;
  color: #38383894;
}

.channels_first_row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 1rem;
}

.channels_first_row_love {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 2rem;
}

.channels_first_row li {
  list-style: none;
  font-size: 1.1rem;
  text-align: left;
  margin: 0;
  padding: 0;
}

.channels_first_row img {
  width: 3.8rem;
  height: auto;
  display: block;
}

._channel_tags p {
  font-size: 0.8rem;
  border: 0.8px solid #38383894;
  padding: 0.3rem 0.5rem;
  border-radius: 0.3rem;
  white-space: nowrap;
}

._channel_details_section {
  max-width: 100%;
  overflow: hidden;
  margin: 2rem 0;
}

._channels_logos {
  width: 2.5rem;
  height: auto;
  display: block;
  border-radius: 50%;
}

._creator_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.3rem auto 2.3rem;
}

._sponsored_video_info,
._sponsored_video_img_desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._video_sponsors_sec {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
  overflow: hidden;
}

/* Container for "Has Worked With" section */
._has_worked_with_container {
  grid-column: 1 / 2;
  overflow-y: auto;
  padding-right: 1rem;
  position: relative;
  height: calc(100vh - 21.5rem);
}

/* Container for "Sponsored Videos" section */
._sponsored_videos_container {
  grid-column: 2 / 3;
  overflow-y: auto;
  padding-right: 1rem;
  position: relative;
  height: calc(100vh - 21.5rem);
}

._has_worked_with_container div {
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 2rem;
  cursor: pointer;
}

.sV_container {
  margin-top: 2rem;
}

._has_worked_with_container img {
  width: 3rem;
}

._sponsored_video {
  margin-top: 2rem;
}

._channel_img {
  width: 4.6rem;
  border-radius: 50%;
}

._details_name p {
  font-size: 0.9rem;
  color: #38383894;
  letter-spacing: 0.3px;
}

/* ._details_name h3, ._details_name p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
} */

._channel_link span {
  display: flex;
  flex-direction: column;
  width: auto;
}

._channel_link ._niche {
  font-size: 1rem;
  padding: 0.2rem 0.42rem;
  color: #000;
}

._youtube_icon {
  font-size: 3rem;
  cursor: pointer;
  color: hsl(0, 93%, 47%);
}

._back_btn {
  border: 1.5px solid #a488f8;
  border-radius: 0.4rem;
  background: transparent;
  color: #a488f8;
  padding: 0.3rem 0.5rem;
  gap: 0.3rem;
  cursor: pointer;
}

._sponsored_video_thumbnail {
  border-radius: 1rem;
  object-fit: cover;
  width: 20rem;
  height: 11rem;
  cursor: pointer;
}

._desc_title_container {
  width: 85%;
}

._sponsored_video_desc {
  flex-wrap: wrap;
  width: 80%;
}

._videos_container {
  margin: 2rem 0;
}

._sponsored_video_container {
  margin: 2rem 0;
}

._sVideo_title {
  font-size: 1rem;
  letter-spacing: 0.3px;
}

._sVideo_desc {
  font-size: 1rem;
  color: #292727cb;
}

._date_de_sponsor {
  font-size: 0.9rem;
  color: #292727cb;
}

._sVideos_title {
  font-size: 1.5rem;
  letter-spacing: .4px;
  position: sticky; /* Makes the element sticky */
  top: 0;
  color: #5f35aa;
  z-index: 1; /* Stays above other content */
  padding: 0.5rem 0;
  background-color: #ffffff;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

._channel_niche {
  width: 10rem;
  color: #38383894;
  font-size: 1rem;
  padding: 0.2rem 0.42rem;
}
