._notify_container {
  width: 60%;
  margin: 0 auto;
  background-color: rgb(252, 255, 255);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem 5rem;
  border-radius: 1rem;
  z-index: 10000;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

._notice_close_icon {
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
}

._notice_title {
  color: #4a1a98;
  font-size: 2.8rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

._notice_text {
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 1rem auto;
}