.pricing {
  margin: 3rem auto;
  background-color: #f8f8f8;
  border-radius: 1rem;
  padding: 3rem 1.2rem;
  background-image: url("../../Assets/Lines.svg");
  background-position: center;
  max-width: 1280px;
}

._prices_container {
  display: flex;
  gap: 3.8rem;
  justify-content: center;
  height: auto;
  flex-grow: 1;
  align-items: stretch;
  margin: 3rem auto;
}

/* .pricing_item2 {
  flex-grow: 1;
} */

._pricing_divs div:nth-child(1) {
  background-color: white;
}

._pricing_divs div:nth-child(2) {
  background-color: #272b31;
}

._pricing_title {
  display: flex;
  flex-direction: column;
  margin: 0 auto 5rem;
  justify-content: center;
  align-items: center;
}

._pricing_title h1 {
  color: #4a1a98;
  font-size: 4.2rem;
  margin: 0.42rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

._pricing_title p {
  font-size: 1.5rem;
  width: 65%;
  text-align: center;
  line-height: 1.3;
}

/* ._princing_divs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 70%;
  margin: 1rem auto;
} */

#original_price {
  font-size: 1.5rem;
  text-decoration: line-through;
}

.dis_price {
  color: #fcad05;
  font-size: 1.2rem;
  font-style: italic;
  margin-left: 0.3rem;
}

._billed_an {
  font-size: 1.1rem;
  color: #844edf;
}

._cancel_text {
  font-size: 1.1rem;
  color: #fcad05;
}

.pricing_item1 h4,
.pricing_item2 h4 {
  margin: 0.5rem 0;
  font-size: 2rem;
}

.pricing_item1 {
  border: 1.5px solid rgb(209, 213, 219);
}

.pricing_item1,
.pricing_item2 {
  background-color: #ffff;
  border-radius: 0.8rem;
  padding: 1rem;
  display: flex;
  gap: 1.6rem;
  flex-direction: column;
  justify-content: space-between;
  max-width: 20rem;
  min-height: 29rem;
  flex-grow: 1;
}

.pricing_item2 {
  background-color: #272b31;
  color: #ffff;
}

.pricing_item1 button,
.pricing_item2 button {
  color: #272b31;
  background-color: #fcad05;
  border: transparent;
  border-radius: 0.42rem;
  padding: 0.5rem 0 0.2rem;
  font-size: 2.3rem;
  height: 3rem;
  font-weight: 500;
  cursor: pointer;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

.pricing_item1 button {
  background-color: #272b31;
  color: #fcad05;
}

._pricing_list span {
  font-size: 1.3rem;
  display: flex;
  align-items: center;
}

._pricing_list li {
  font-size: 1.3rem;
  list-style: none;
  margin: 0.6rem 0;
  display: flex;
  gap: 0.6rem;
  align-items: center;
  justify-content: flex-start;
}

._price {
  font-size: 2.42rem;
  font-family: "Inter Tight", sans-serif;
}

._price span {
  font-size: 1.3rem;
  color: #844edf;
}

.pricing_item p {
  font-size: 1.16rem;
  font-weight: 400;
  color: rgb(90, 91, 91);
  font-family: "Inter Tight", sans-serif;
}

.pricing_item2 p {
  color: #e4e4e4;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.12rem;
  font-weight: 400;
}

._pricing_list li p {
  font-weight: 500;
  font-size: 1.2rem;
  font-family: "Inter Tight", sans-serif;
}

/* ._p_sub_title p {
  width: 70%;
} */

._pricing_list p span,
._p_sub_title p span {
  font-style: italic;
  font-size: 1rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

.pricing_btns_div {
  background-color: #fff;
  padding: 0.5rem 0.8rem;
  border-radius: 0.8rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  gap: 0.6rem;
  width: 25%;
}

.pice_btns {
  font-size: 1.2rem;
  padding: 0.4rem 0.6rem;
  width: 100%;
  border-radius: 0.3rem;
  border: transparent;
  background-color: #e4e4e4;
  cursor: pointer;
  color: #272b31;
}

.pice_btns_active {
  background-color: #fcad05;
}

._switcher {
  position: relative;
  width: 118px;
  height: 50px;
  display: inline-block;
  margin: 2rem 0;
}

._slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 34px;
}

._switcher input {
  display: none;
}

._slider::before {
  position: absolute;
  content: "";
  width: 42px;
  height: 42px;
  left: 4px;
  bottom: 4px;
  background-color: #f0eeea;
  transition: 0.4s;
  border-radius: 50px;
}

._switcher input:checked + ._slider {
  background-color: #fcad05;
}

._switcher input:checked + ._slider::before {
  transform: translateX(68px);
}

@media screen and (max-width: 1042px) {
  ._princing_divs {
    width: 80%;
  }

  @media screen and (max-width: 800px) {
    .pricing_btns_div {
      border-radius: 0.5rem;
      width: 50%;
    }

    ._prices_container {
      display: flex;
      gap: 2rem;
    }

    ._pricing_title {
      width: 80%;
      text-align: center;
      line-height: 1.5;
    }

    ._princing_divs {
      width: 95%;
    }

    ._pricing_title h1 {
      font-size: 3.2rem;
      margin: 0.2rem;
    }

    ._pricing_title p {
      width: 98%;
    }

    @media screen and (max-width: 600px) {
      .pricing {
        margin: 6.6rem auto;
        padding: 2rem 0.3rem;
        width: 97%;
      }

      .pricing_btns_div {
        width: 90%;
      }

      ._pricing_title {
        width: 90%;
        text-align: center;
        line-height: 1.3;
      }

      ._pricing_title p {
        font-size: 1.2rem;
      }

      ._pricing_title h1 {
        font-size: 2.3rem;
      }

      ._princing_divs {
        flex-direction: column;
        width: 90%;
      }

      ._prices_container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 1rem auto;
      }
    }
  }
}
